<template>
	<div class="home">
		
		<div class="box1">
			<div class="box2">
		<!-- <div class="gonggao" v-if="gonggaoList.length > 0"> -->
				<div class="gonggao">
					<img src="../../assets/home/image1.png" />
					<span>公告</span>
					<p>公司通知公告内容公司通知公告内容公司通知</p>
				</div>
				<div class="personalinfobox">
					<div class="avatar">{{realName1}}</div>
					<!-- <img :src="userInfo.headPortrait" v-if="userInfo.headPortrait" class="avatar"> -->
					<!-- <img src="@/assets/home/image12.png" v-else class="avatar"> -->
					<div class="infotext">
						<div class="infotitle">
							您好，{{userInfo.realName}}！ 世事千帆过，前方终会是温柔和月光。
						</div>
						<div class="daoqitime">
							到期时间：2022-12-14 12:00:00
						</div>
						<div class="infobox">
							<div class="oneinfo">
								<img src="@/assets/newhome/image7.png" style="height: 1.0625em;width: 0.8125em;">
								<span>姓名：{{userInfo.realName || '暂无'}}</span>
							</div>
							<div class="oneinfo">
								<img src="@/assets/newhome/image8.png" >
								<span>手机号：{{userInfo.mobile || '暂无'}}</span>
							</div>
							<div class="oneinfo">
								<img src="@/assets/newhome/image9.png" style="height: 0.9375em;width: 0.9375em;">
								<span>注册时间：{{userInfo.createdTime || '暂无'}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="boxonecontent">
					<div class="box2title">
						<img src="../../assets/home/image5.png" />
						<span>数据简报</span>
					</div>
					<div class="box4">
						<div class="box3">
							
							<div class="taskboxbox3content">
								<div class="taskbox">
									<div class="taskboxtitle">
										<img src="../../assets/newhome/image4.png" >
										<span class="tasktitletext" style="color: #F22E18;">逾期未处理事件警告</span>
									</div>
									<div class="taskboxdata">
										0
									</div>
								</div>
								<div class="taskbox">
									<div class="taskboxtitle">
										<img src="../../assets/newhome/image1.png" >
										<span class="tasktitletext" style="color: #F22E18;">今日重要事件处理提醒</span>
									</div>
									<div class="taskboxdata">
										0
									</div>
								</div>
								<div class="taskbox">
									<div class="taskboxtitle">
										<img src="../../assets/newhome/image6.png" >
										<span class="tasktitletext">待处理紧急任务</span>
									</div>
									<div class="taskboxdata">
										0
									</div>
								</div>
								<div class="taskbox">
									<div class="taskboxtitle">
										<img src="../../assets/newhome/image2.png" >
										<span class="tasktitletext">今日待办事项</span>
									</div>
									<div class="taskboxdata">
										0
									</div>
								</div>
								<div class="taskbox">
									<div class="taskboxtitle">
										<img src="../../assets/newhome/image3.png" >
										<span class="tasktitletext">全部待完成事项</span>
									</div>
									<div class="taskboxdata">
										0
									</div>
								</div>
							
								
							</div>
						</div>
					
					</div>
				</div>
				<div class="boxonecontent" style="margin-top: 0.9375em;">
					<div class="box2title">
						<img src="../../assets/home/image5.png" />
						<span>数据看板</span>
					</div>
					<div class="box4">
						<div class="box3">
							
							<div class="box3content">
								<div class="box5"  @click="goGenjinCustomer">
									<div>
										<span style="color: #F22E18;">{{ customerdata.waitCustomerNum }}</span>
										
									</div>
									<p>待跟进客户(个)</p>
								</div>
								<div class="box5" @click="goMonthCustomer">
									<div>
										<span>{{ customerdata.addCustomerMonth }}</span>
										
									</div>
									<p>本月新增客户(个)</p>
								</div>
								<div class="box5" @click="goAllCustomer">
									<div>
										<span>{{ customerdata.totalCustomerNum }}</span>
										
									</div>
									<p>客户总数(个)</p>
								</div>
								<div class="box5" @click="goAllShangji">
									<div>
										<span>{{ customerdata.totalChanceCustomerNum }}</span>
										
									</div>
									<p>商机总数(个)</p>
								</div>
								<div class="box5" @click="goMonthClues">
									<div>
										<span>{{ customerdata.addThreadCustomerNum }}</span>
										
									</div>
									<p>本月新增线索(个)</p>
								</div>
								<div class="box5" @click="goMonthShangji">
									<div>
										<span>{{ customerdata.addChanceCustomerNum }}</span>
										
									</div>
									<p>本月新增商机(个)</p>
								</div>
								<div class="box5">
									<div>
										<span style="color: #F22E18;">0</span>
										
									</div>
									<p>项目总数(个)</p>
								</div>
								<div class="box5">
									<div>
										<span>0</span>
										
									</div>
									<p>已完成项目(个)</p>
								</div>
								<div class="box5">
									<div>
										<span>0</span>
										
									</div>
									<p>进行中项目(个)</p>
								</div>
								<div class="box5">
									<div>
										<span style="color: #F22E18;">0</span>
										
									</div>
									<p>逾期项目(个)</p>
								</div>
								<div class="box5">
									<div>
										<span style="color: #F22E18;">0</span>
										
									</div>
									<p>待跟进项目(个)</p>
								</div>
								<div class="box5">
									<div>
										<span>0</span>
										
									</div>
									<p>本月新增项目(个)</p>
								</div>
									<div class="box5">
										<div>
											<span>0</span>
											
										</div>
										<p>今日回款(个)</p>
									</div>
									<div class="box5">
										<div>
											<span>0</span>
											
										</div>
										<p>待回款(个)</p>
									</div>
									<div class="box5">
										<div>
											<span>0</span>
											
										</div>
										<p>已结款(个)</p>
									</div>
									<div class="box5">
										<div>
											<span style="color: #F22E18;">0</span>
											
										</div>
										<p>已逾期(个)</p>
									</div>
									<div class="box5">
										<div>
											<span>0</span>
											
										</div>
										<p>一次性回款(个)</p>
									</div>
									<div class="box5">
										<div>
											<span>0</span>
											
										</div>
										<p>分期回款(个)</p>
									</div>
							</div>
						</div>
					
					</div>
				</div>
				
			</div>
			<div class="box6">
				<div class="box6title">
					<img src="../../assets/home/image4.png" />
					<span>系统通知</span>
				</div>
				<div class="noimagebox" v-if="systemnoticeList.length < 1">
					<img src="@/assets/home/image11.png" class="noimage">
					<span>当前暂无通知～</span>
				</div>
				<div class="box7" v-for="(item, index) in systemnoticeList" :key="index" @click="chooseNotice(index)">
					<div class="box8">
						<div class="flex">战报</div>
						<span>{{item.title}}</span>
					</div>
					<!-- <p>{{item.mouthday}}</p> -->
					<p>{{item.createdTime}}</p>
				</div>
			</div>
		</div>
		<div class="box9">
			<div class="allech">
				<div class="fenxititle">
					<img src="../../assets/home/image3.png" />
					<span>数据分析</span>
				</div>
				<div class="box10">
					<div style="width: 100%;padding: 0 3.75em 0 1.3em;">
						<div class="newtitle">本月新增客户趋势</div>
						<div class="nodata" v-if="mouthday.length < 1 && mouthdata.length < 1">
							<img src="@/assets/home/image10.png" >
							<span>暂无数据</span>
						</div>
						<div id="main"></div>
					</div>
					<div style="width: 32%;" v-show="false">
						<div class="newtitle">客户画像分布</div>
						<div class="nodata" v-if="huaxiangData.length < 1">
							<img src="@/assets/home/image10.png" >
							<span>暂无数据</span>
						</div>
						<div id="main1"></div>
					</div>
				</div>
				<div class="threetixing">
					<div class="onetixing">
						<div class="txtitle">客户阶段漏斗统计</div>
						<div id="firsttx" class="tixing"></div>
					</div>
					<div class="onetixing">
						<div class="txtitle">线索转化漏斗统计</div>
						<div id="secondtx" class="tixing"></div>
					</div>
					<div class="onetixing">
						<div class="txtitle">商机转化漏斗统计</div>
						<div id="thirdtx" class="tixing"></div>
					</div>
				</div>
			</div>
			<div class="box6">
				<div class="box6title">
					<img src="../../assets/home/image7.png" />
					<span>待办事项</span>
				</div>
				<rili></rili>
			</div>
		</div>
		<el-drawer
		  title="通知内容"
		  :visible.sync="drawer"
		  direction="rtl"
		  >
		  <div class="noticetan" v-if="systemnoticeList.length > 0">
		  	{{systemnoticeList[noticeIndex].title}}
		  </div>
		</el-drawer>
	</div>
</template>

<script>
	import { mapState } from "vuex";
import rili from './rili.vue';
import {
	noticeList,
	customerPieChart,
	customerTendencyChart,
	statisticsCustomer,
	operationLogList,
	scheduleNum
} from '@/network/api.js';
import * as echarts from 'echarts';
export default {
	data() {
		return {
			realName1:'',
			gonggaoList: [],
			noticeIndex: 0,
			drawer: false,
			huaxiangData: [],
			systemnoticeinfo: {
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 20,
				searchBody: ''
			},
			noticeinfo: {
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 20,
				searchBody: ''
			},
			customerdata: {},
			mouthday: [],
			mouthdata: [],
			systemnoticeList: [],  // 系统通知
			endTime: '',
			startTime: '',
		};
	},
	name: 'Home',
	computed: {
		...mapState(['userInfo'])
	},
	mounted() {
		let that = this;
		this._customerPieChart();
		this._customerTendencyChart();
		this.createOnetx()
		this.createTwotx()
		this.createThreetx();
		setTimeout(function(){
			if(that.userInfo.realName.length>2){
				that.realName1 = that.userInfo.realName.substring(that.userInfo.realName.length-2)
			}else{
				that.realName1 = that.userInfo.realName
			}
		},500)
	},
	components: {
		rili
	},
	created() {
		this._noticeList();
		this._statisticsCustomer();
		this._operationLogList();
		this.getYear()
	},
	methods: {
		// 第一个梯形
		createThreetx() {
			var chartDom = document.getElementById('thirdtx');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				textStyle: {
					color: '#666666'
				},
			  series: [
			    {
						color: ['#3590FF','#40A9FF','#69BFFF','#94D1FF'],
			      type: 'funnel',
			      left: '0%',
			      top: 0,
			      bottom: 0,
			      width: '70%',
						height: '100%',
			      min: 0,
			      max: 100,
			      minSize: '0%',
			      maxSize: '100%',
			      sort: 'descending',
			      gap: 2,
			      labelLine: {
			        length: 40,
			        lineStyle: {
			          width: 1,
			          type: 'dashed',
								color: '#BBBBBB'
			        }
			      },
			      itemStyle: {
			        borderColor: '#fff',
			        borderWidth: 1
			      },
			      emphasis: {
			        label: {
			          fontSize: 20
			        }
			      },
			      data: [
			   
			        { value: 100, name: '未转化' },
			        { value: 80, name: '转化客户' },
			      ]
			    }
			  ]
			};
			
			option && myChart.setOption(option);
		},
		// 第二个梯形
		createTwotx() {
			var chartDom = document.getElementById('secondtx');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				textStyle: {
					color: '#666666'
				},
			  series: [
			    {
						color: ['#3590FF','#40A9FF','#69BFFF','#94D1FF'],
			      type: 'funnel',
			      left: '0%',
			      top: 0,
			      bottom: 0,
			      width: '70%',
						height: '100%',
			      min: 0,
			      max: 100,
			      minSize: '0%',
			      maxSize: '100%',
			      sort: 'descending',
			      gap: 2,
			      labelLine: {
			        length: 40,
			        lineStyle: {
			          width: 1,
			          type: 'dashed',
			        	color: '#BBBBBB'
			        }
			      },
			      itemStyle: {
			        borderColor: '#fff',
			        borderWidth: 1
			      },
			      emphasis: {
			        label: {
			          fontSize: 20
			        }
			      },
			      data: [
			        
			        { value: 100, name: '未转化' },
			        { value: 80, name: '转化商机' },
			        { value: 60, name: '转化客户' },
			      ]
			    }
			  ]
			};
			
			option && myChart.setOption(option);
		},
		// 第一个梯形
		createOnetx() {
			var chartDom = document.getElementById('firsttx');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				textStyle: {
					color: '#666666'
				},
			  series: [
			    {
						color: ['#3590FF','#40A9FF','#69BFFF','#94D1FF'],
			      type: 'funnel',
			      left: '0%',
			      top: 0,
			      bottom: 0,
			      width: '70%',
						height: '100%',
			      min: 0,
			      max: 100,
			      minSize: '0%',
			      maxSize: '100%',
			      sort: 'descending',
			      gap: 2,
			      labelLine: {
			        length: 40,
			        lineStyle: {
			          width: 1,
			          type: 'dashed',
			      		color: '#BBBBBB'
			        }
			      },
			      itemStyle: {
			        borderColor: '#fff',
			        borderWidth: 1
			      },
			      emphasis: {
			        label: {
			          fontSize: 20
			        }
			      },
			      data: [
			        { value: 100, name: '成功' },
			        { value: 80, name: '方案洽谈' },
			        { value: 60, name: '报价确认' },
			        { value: 40, name: '报价失败' },
			      ]
			    }
			  ]
			};
			
			option && myChart.setOption(option);
		},
		// 跳转本月新增用户
		goMonthCustomer() {
			
			this.$router.push({
				path: '/customermanage',
				query: {
					endTime: this.endTime,
					startTime: this.startTime,
				}
			})
		},
		// 跳转所有商机
		goAllShangji() {
			
			this.$router.push({
				path: '/shangjimanage'
			})
		},
		// 跳转本月新增线索
		goMonthClues() {
			
			this.$router.push({
				path: '/cluesmanager',
				query: {
					endTime: this.endTime,
					startTime: this.startTime,
				}
			})
		},
		// 跳转本月商机
		goMonthShangji() {
			
			this.$router.push({
				path: '/shangjimanage',
				query: {
					endTime: this.endTime,
					startTime: this.startTime,
				}
			})
		},
		// 获取当前时间
		getYear() {
			let time = new Date();
			let thisyear = time.getFullYear();
			let year = thisyear;
			let month = time.getMonth() + 1;
			var thisDate = new Date(year, month, 0); //当天数为0 js自动处理为上一月的最后一天
			let monthDays = thisDate.getDate();
			this.startTime = year + '-' + month + '-' + '01' + ' ' + '00:00:00'
			this.endTime = year + '-' + month + '-' + monthDays + ' ' + '23:59:59'
		},
		// 跳转待跟进用户
		goGenjinCustomer() {
			this.$router.push({
				path: '/customermanage',
				query: {
					isWait: 1
				}
			})
		},
		// 跳转所有用户
		goAllCustomer() {
			this.$router.push({
				path: '/customermanage'
			})
		},
		// 选择提示打开
		chooseNotice(index) {
			this.drawer = true,
			this.noticeIndex = index
		},
		// 获取首页操作日志
		async _operationLogList() {
			const res = await operationLogList(this.systemnoticeinfo);
			// console.log('首页操作日志', res);
			this.systemnoticeList = res.data.list
			this.systemnoticeList.forEach(item => {
				item.mouthday = item.createdTime.slice(item.createdTime.indexOf('-')+1)
			})
		},
		// 获取客户统计
		async _statisticsCustomer() {
			const res = await statisticsCustomer();
			// console.log('客户统计', res);
			this.customerdata = res.data;
		},
		// 获取本月客户新增客户
		async _customerTendencyChart() {
			const res = await customerTendencyChart();
			// console.log('本月客户趋势', res);
			res.data = res.data.reverse()
			res.data.forEach(item => {
				for (let key in item) {
					// console.log(item[key]);
					this.mouthday.push(key);
					this.mouthdata.push(item[key]);
				}
			});
			this.createEch();
		},
		// 获取客户画像分布
		async _customerPieChart() {
			const res = await customerPieChart();
			// console.log('客户画像分布', res);
			this.huaxiangData = res.data.map(item => {
				return {
					value: item.num,
					name: item.tagName
				};
			});
			this.createEch1();
		},
		// 获取公告列表
		async _noticeList() {
			const res = await noticeList(this.noticeinfo);
			// console.log('公告列表', res);
			this.gonggaoList = res.data.list
		},
		// 客户画像分布图表
		createEch1() {
			var chartDom = document.getElementById('main1');
			var myChart = echarts.init(chartDom);
			var option;

			option = {
				grid: {
					left: 'left',
					height: '100%',
					width: '100%',
					bottom: '0',
					containLabel: true
				},

				tooltip: {
					trigger: 'item'
				},
				// legend: {
				//   orient: 'vertical',
				//   left: 'left'
				// },
				series: [
					{
						label: {
							alignTo: 'edge',
							formatter: '{name|{b}}：{c}\n',
							minMargin: 5,
							edgeDistance: 10,
							lineHeight: 15,
							rich: {
								time: {
									fontSize: 10,
									color: '#999'
								}
							}
						},
						labelLine: {
							length: 15,
							length2: 0,
							maxSurfaceAngle: 80
						},
						labelLayout: function(params) {
							const isLeft = params.labelRect.x < myChart.getWidth() / 2;
							const points = params.labelLinePoints;
							// Update the end point.
							points[2][0] = isLeft
								? params.labelRect.x
								: params.labelRect.x + params.labelRect.width;
							return {
								labelLinePoints: points
							};
						},
						name: '意向客户',
						type: 'pie',
						radius: '80%',
						data: this.huaxiangData,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			};
			option && myChart.setOption(option);
		},
		// 本月新增客户趋势图表
		createEch() {
			var chartDom = document.getElementById('main');
			var myChart = echarts.init(chartDom);
			var option;

			option = {
				tooltip: {
					trigger: 'axis'
				},
				xAxis: {
					name: '日期',
					type: 'category',
					data: this.mouthday,
					color: '#999999',
					axisLine: {
						lineStyle: {
							color: '#DDDDDD'
						}
					},

					axisLabel: {
						textStyle: {
							color: '#999999' //坐标值文字得具体的颜色
						}
					}
				},
				center: ['100%', '100%'],
				yAxis: {
					name: '人数',
					minInterval: 1, //只显示整数
					type: 'value',
					axisLine: {
						lineStyle: {
							color: '#DDDDDD'
						}
					},
					axisLabel: {
						textStyle: {
							color: '#999999' //坐标值文字得具体的颜色
						}
					}
				},
				grid: {
					x: 40,
					x2: 40,
					y2: 24,
					left: 'left',
					height: '80%',
					width: '100%',
					bottom: '0',
					containLabel: true
				},
				series: [
					{
						symbol: 'none',
						pointer: {
							length: '70%',
							width: 40,
							itemStyle: {
								color: '#ffffff'
							}
						},
						data: this.mouthdata,
						type: 'line',
						smooth: true,
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(90,171,255,.5)'
									},
									{
										offset: 1,
										color: 'rgba(90,171,255, 0)'
									}
								])
							}
						},
						itemStyle: {
							normal: {
								lineStyle: {
									color: 'rgb(90,171,255)'
								}
							}
						}
					}
				]
			};

			option && myChart.setOption(option);
		}
	}
};
</script>
<style scoped="scoped" lang="scss">
	.home {
		width: 100%;
		// overflow-x: hidden;
	}
	.txtitle {
		margin: 3.125em 0 1.25em 0;
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #222222;
	}
	.tixing {
		width: 100%;
		height: 12.3125em;
	}
	.onetixing {
		width: 33.3%;
	}
	.threetixing {
		padding: 0 0 0 1.5625em;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	// .home {
	// 	overflow: scroll;
	// }
	// ::-webkit-scrollbar {
	// 	width: 0.375em;
	// 	background-color: rgb(255, 255, 255);
	// }
	// ::-webkit-scrollbar-thumb {
	// 	border-radius: 0.1875em;
	// 	background-color: rgb(172, 172, 172);
	// }
	.taskbox {
		
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 0 0 0.8em 0;
	}
	.taskboxtitle {
		display: flex;
		align-items: center;
		img {
			width: 1.0625em;
			height: 1.0625em;
			margin: 0 0.4375em 0 0;
		}
		span {
			font-size: 1.375em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #111111;
		}
	}
	.taskboxdata {
		font-size: 1.625em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #F22E18;
		margin-top: 0.5em;
	}
	.boxonecontent {
		background-color: #ffffff;
		padding: 1.25em;
		
		width: 100%;
	}
	.daoqitime {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #868E99;
		margin: 0.9375em 0 1.0625em 0;
	}
	.nodataproject {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		img {
			width: 7em;
			height: 6em;
		}
		span {
			margin: 1.5em 0 0;
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #868E99;
		}
	}
	.nodata {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		img {
			width: 14.375em;
			height: 12em;
		}
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #868E99;
		}
	}
	.noimagebox {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		img {
			width: 11.0625em;
			height: 9.0625em;
		}
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #868E99;
		}
	}
	.oneinfo {
		img {
			width: 0.875em;
			height: 0.875em;
			margin: 0 0.625em 0 0;
		}
		display: flex;
		align-items: center;
		i {
			margin: 0 0.3em 0 0;
		}
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #ACB3BD;
			margin: 0 1.875em 0 0;
		}
	}
	.infobox {
		display: flex;
		align-items: center;
	
	}
	.infotitle {
		font-size: 1.5em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 700;
		color: #374567;
	}
	.avatar {
		width: 4.25em;
		color: #fff;
		font-size: 20px;
		box-sizing: border-box;
		height: 4.25em;
		border-radius: 50%;
		background-color: #3286ff;
		margin: 0 1.25em 0 0;
		line-height:4.25em ;
		text-align: center;
	}
	.personalinfobox {
		width: 100%;
		padding: 1.25em 1.5em 1.0625em;
		// height: 7.5em;
		background-color: #ffffff;
		margin: 0.9375em auto;
		display: flex;
		align-items: center;
	}
	.noticetan {
		padding: 20px;
	}
#main1 {
	width: 100%;
	height: 12.5em;
	margin-top: 1.25em;
}
.box10 {
	width: 100%;
	display: flex;
	// align-items: center;
	justify-content: space-between;
}
#main {
	width: 100%;
	height: 12.5em;
}
.newtitle {
	margin: 1.25em 0 0 0;
	font-size: 1em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #222222;
}
.fenxititle {
	display: flex;
	align-items: center;
	img {
		width: 0.9375em;
		height: 0.9375em;
		margin: 0 0.625em 0 0;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
}
.allech {
	width: 69.5%;
	padding: 1.25em;
	background-color: #ffffff;
	height: 51.25em;
}
.box9 {
	width: 100%;
	position: relative;
	padding: 0 1.25em;
	min-width: 1000px;
	margin: 0.9375em 0 0 0;
}
.box8 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	
	width: 100%;
	div {
		width: 2.775em;
		height: 1.525em;
		background: rgba(250, 100, 0, 0.06);
		border-radius: 0.78em 0px 0.75em 0.78em;
		border: 1px solid #fa6400;
		font-size: 0.75em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #fa6400;
		margin: 0 0.9375em 0 0;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 90%;
	}
}
.box7 {
	cursor: pointer;
	width: 100%;
	padding: 0 1.25em 0 1.25em;
	display: flex;
	// align-items: center;
	// justify-content: space-between;
	flex-direction: column;
	margin: 0 0 1.125rem 0;
	p {
		margin: 0.625em 0 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
}
.box6title {
	display: flex;
	align-items: center;
	padding: 1.125em 0 0 0.75em;
	margin: 0 0 1.25rem 0;
	img {
		width: 1em;
		height: 1em;
		margin: 0 0.9375em 0 0;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #2a2a2a;
	}
}
.box6 {
	position: absolute;
	right: 1.25em;
	// right: 0;
	top: 0;
	height: 100%;
	width: 29%;
	background-color: #ffffff;
	overflow-y: scroll;
}
.box5 {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 0 0 0.9375em 0;
	width: 14.2%;
	span {
		font-size: 1.625em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		margin: 0 0.375em 0 0;
	}
	p {
		margin-top: 0.6em;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #666666;
	}
}
.box3title {
	margin: 1.125em 0 0.9375em 0;
	font-size: 1em;
	font-family: PingFang-SC-Regular, PingFang-SC;
	font-weight: 400;
	color: #222222;
}
.box3 {
	width: 100%;
	// width: calc((100% - 4rem) / 3);
	// width: calc(100% - 4rem);
}
.box4 {
	padding: 0 1.25em 0 1.6em;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.taskboxbox3content{
	padding: 1.25em 1em 0.35em;
	width: 100%;
	margin: 1.125em 0 0;
	// height: 10.625em;
	background: #faf9fb;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.box3content {
	padding: 1.25em 0 0.35em;
	width: 100%;
	margin: 1.125em 0 0;
	// height: 10.625em;
	background: #faf9fb;
	border-radius: 4px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.box2 {
	width: 69.5%;
	// padding: 1.25em;
	// background-color: #ffffff;
	// margin: 0 0.9375em 0 0;
}
.box1 {
	// display: flex;
	// justify-content: space-between;
	width: 100%;
	// width: calc(100vw - 8px);
	min-width: 1000px;
	position: relative;
	padding: 0 1.25em;
}
.box2title {
	display: flex;
	align-items: center;
	img {
		width: 1em;
		height: 0.9375em;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #303030;
		margin: 0 0 0 0.6875em;
	}
}
.gonggao {
	width: 100%;
	margin: 0.9375em auto 0;
	height: 3.25em;
	background: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 1.125em;
	img {
		width: 1.375em;
		height: 1.375em;
		border-radius: 50%;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #f22e18;
		margin: 0 0.8125em 0 0.375em;
	}
	p {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #555555;
	}
}

@media screen and (max-width: 1920px) {
	.home {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.home {
		font-size: 15px;
	}
	.tasktitletext {
		font-size: 1.2em !important;
	}
}
@media screen and (max-width: 1520px) {
	.home {
		font-size: 14px;
	}
	.tasktitletext {
		font-size: 1.1em !important;
	}
}
@media screen and (max-width: 1320px) {
	.home {
		font-size: 12px;
	}
	// .tasktitletext {
	// 	font-size: 1em !important;
	// }
}
@media screen and (max-width: 1120px) {
	.home {
		font-size: 10px;
	}
}
</style>
