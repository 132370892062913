<template>
	<div class="rili">
		<div class="rilibox">
			<div class="box1">
				<div>
					<el-select v-model="year" placeholder="请选择" @change="selectChange">
						<el-option
							v-for="item in yearList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
					<el-select
						v-model="month"
						placeholder="请选择"
						style="margin-left: 1em;"
						@change="selectChange"
					>
						<el-option
							v-for="item in monthList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div>
				<div class="backbtn flex" @click="goBackThisDay">返回今天</div>
			</div>

			<div class="box2">
				<div class="box3">一</div>
				<div class="box3">二</div>
				<div class="box3">三</div>
				<div class="box3">四</div>
				<div class="box3">五</div>
				<div class="box3">六</div>
				<div class="box3">日</div>
				<div class="box4" v-for="(item, index) in emptyDays">
					<span>{{ beforeempty + index + 1 }}</span>
				</div>
				<div class="box41" v-for="(item, index) in monthDays" @click="clickChooseIndex(index)">
					<div class="box4" v-if="scheduledata[index+1] < 1">
						<span class="box4span" :class="{ activespan: tadayIndex == index && chooseIndex == index,activespan: chooseIndex == index, choosespan: tadayIndex == index && chooseIndex != index}">{{ item, }}</span>
					</div>
					<el-popover
						v-if="scheduledata[index+1] > 0"
						class="box4"
						style="cursor: pointer;"
						placement="right-start"
						width="300"
						trigger="click"
						@show="showPopover(index)"
						@hide="hidePopover"
						
					>
						<div class="boxtan">
							<div class="box5">
								<p>{{ month }}月</p>
								<span v-if="item > 10">{{ item }}</span>
								<span v-else>{{ '0' + item }}</span>
							</div>
							<div class="line"></div>
							<div class="allbox6">
								<div class="box6" v-for="(item,index) in oneschedule" :key="index">
									<!-- <p>日程计划标题</p> -->
									<span class="box6span">{{item.scheduleContent}}</span>
									<div class="box7">
										<img src="@/assets/home/image2.png" />
										<span>{{item.startTime}}-{{item.endTime}}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="box4" slot="reference" @click="clickChooseIndex(index)">
							<span class="box4span" style="color: #000000;" :class="{ activespan: tadayIndex == index && chooseIndex == index,activespan: chooseIndex == index, choosespan: tadayIndex == index && chooseIndex != index }">{{ item }}</span>
							<div class="box4div" v-if="scheduledata[index+1] > 0">{{scheduledata[index+1]}}</div>
						</div>
					</el-popover>
				</div>

				<div class="box4" v-for="(item, index) in nextempty">
					<span class="box4span">{{ index + 1 }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { scheduleNum,scheduleListByTime } from "@/network/api.js"
export default {
	data() {
		return {
			tadayIndex: 0,
			chooseIndex: -1,
			yearList: [],
			monthList: [],
			year: '',
			month: '',
			day: '',
			monthDays: '',
			emptyDays: 0,
			beforeMonthDaysList: [],
			beforeempty: '',
			nextempty: '',
			scheduledata: {},  // 某月的日程数量
			oneschedule: []
		};
	},
	name: 'rili',
	created() {
		this.getYear();
		this.getMonthDays(this.year, this.month);
		this._scheduleNum()
	},
	methods: {
		// 点击返回今天
		goBackThisDay() {
			this.getYear()
			this.getMonthDays(this.year, this.month);
			this._scheduleNum()
		},
		// 点击选择天
		clickChooseIndex(index) {
			this.chooseIndex = index;
			this._scheduleListByTime(index+1)
			console.log('resreeee',index)
		},
		hidePopover() {
			this.oneschedule = []
		},
		// 显示弹框
		showPopover(index) {
			console.log(index);
			this._scheduleListByTime(index+1)
		},
		// 获取某天的事项
		async _scheduleListByTime(index) {
			const res = await scheduleListByTime({yearMonthDay: this.year + '-' + this.month + '-' + index})
			console.log("某天的日程",res);
			this.oneschedule = res.data
		},
		// 获取某个月的代办事项
		async _scheduleNum() {
			const res = await scheduleNum({
				yearMonth: this.year + '-' + this.month.toString().padStart(2,'0')
			})
			console.log('某月的待办事项',res);
			
			this.scheduledata = res.data
		},
		// 获取前一个月有多少天
		getBeforeMonthDays() {
			let days = '';
			if (this.month == 1) {
				let thisDate = new Date(this.year - 1, 12, 0); //当天数为0 js自动处理为上一月的最后一天
				days = thisDate.getDate();
			} else {
				let thisDate = new Date(this.year, this.month - 1, 0); //当天数为0 js自动处理为上一月的最后一天
				days = thisDate.getDate();
			}
			let array = new Array(days);
			this.beforeempty = array.length - this.emptyDays;
			this.nextempty = 7 - ((this.emptyDays + this.monthDays) % 7);
			if (this.nextempty == 7) {
				this.nextempty = 0;
			}
		},
		// 获取一个月的第一天是星期几
		getStartLast() {
			const day = new Date(this.year + '/' + this.month + '/' + 1).getDay();
			if (day == 0) {
				this.emptyDays = 6;
			} else {
				this.emptyDays = day - 1;
			}
			this.getBeforeMonthDays();
		},
		// 日期切换
		selectChange() {
			console.log(this.year);
			this.getMonthDays(this.year, this.month);
			this.getStartLast();
			this._scheduleNum()
		},
		// 获取某一月的天数
		getMonthDays(year, month) {
			var thisDate = new Date(year, month, 0); //当天数为0 js自动处理为上一月的最后一天
			this.monthDays = thisDate.getDate();
			this.getStartLast();
		},
		// 获取年份
		getYear() {
			this.yearList = []
			this.monthList = []
			let time = new Date();
			let thisyear = time.getFullYear();
			this.year = thisyear;
			this.month = time.getMonth() + 1;
			this.day = time.getDate()
			// console.log(this.day);
			this.tadayIndex = this.day - 1
			this.chooseIndex = this.tadayIndex
			for (let i = thisyear - 10; i < thisyear + 10; i++) {
				let data = {
					value: i,
					label: i + '年'
				};
				this.yearList.push(data);
			}
			for (let i = 1; i <= 12; i++) {
				let data = {
					value: i,
					label: i + '月'
				};
				this.monthList.push(data);
			}
			
		}
	}
};
</script>

<style scoped lang="scss">
.boxtan {
	display: flex;
	align-items: flex-start;
}
.box7 {
	display: flex;
	align-items: center;
	img {
		width: 14px;
		height: 14px;
		margin: 0 3px 0 0;
	}
	span {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		margin: 0;
	}
}
.box6span {
	margin: 8px 0 5px 0;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #808080;
}
.box6 {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	p {
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #222222;
	}
}
.line {
	width: 1px;
	height: 70px;
	background-color: #f1f4f6;
	margin: 0 8px 0 14px;
}
.box5 {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}
	span {
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
	}
}
::v-deep {
	.el-popover__reference-wrapper {
		position: relative !important;
		width: 100%;
		min-width: 10%;
		min-height: 4.375em;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 4.375em;
	}
	.el-popover__reference-wrapper {
		position: relative !important;
		width: 100%;
		min-width: 10%;
		min-height: 4.375em;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 4.375em;
	}
}
.box4div {
	position: absolute;
	width: 1.25em;
	height: 1.25em;

	background: rgb(253, 222, 215);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #f22e18;
	top: 0.7em;
	right: -1.4em;
}
.box4span {
	position: relative;
	// font-size: 1em !important;
	// font-family: PingFang-SC-Medium, PingFang-SC;
	// font-weight: 500;
	// color: #a5a8be;
	min-width: 2.125em;
	min-height: 2.125em;
	width: 2.125em;
	height: 2.125em;
	display: flex;
	align-items: center;
	justify-content: center;
	&.activespan {
		background: #f22e18;
		border-radius: 1.25em;
		border: 1px solid #f38686;
		color: #ffffff !important;
	}
	&.choosespan {
		color: #F22E18;
	}
}
.box41 {
	width: 14%;
	min-width: 14%;
	min-height: 4.375em;
	height: 4.375em;
	display: flex;
	align-items: center;
	justify-content: center;
}
.box4 {
	cursor: pointer;
	position: relative !important;
	width: 14%;
	min-width: 14%;
	min-height: 4.375em;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 4.375em;
	span {
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #a5a8be;
	}
	div {
		// font-size: 0.875em;
	}
	// div {
	// 	position: absolute;
	// 	width: 1.25em;
	// 	height: 1.25em;

	// 	background: rgb(253, 222, 215);
	// 	border-radius: 50%;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	font-size: 0.875em;
	// 	font-family: PingFang-SC-Medium, PingFang-SC;
	// 	font-weight: 500;
	// 	color: #f22e18;
	// 	top: 0.7em;
	// 	right: -0.4em;
	// }
}

.box3 {
	margin: 2.1875em 0 1.25em 0;
	width: 14%;
	font-size: 0.9375em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: center;
}
.box2 {
	padding: 0 0.625em;
	width: 100%;
	display: flex;
	align-items: center;

	flex-wrap: wrap;
}
@media screen and (max-width: 1920px) {
	.rili {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.rili {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.rili {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.rili {
		font-size: 12px;
	}
}
@media screen and (max-width: 1120px) {
	.rili {
		font-size: 10px;
	}
}
.box1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.625em 0 2.5em;
}
.backbtn:active {
	opacity: 0.6;
}
.backbtn {
	width: 5.5em;
	height: 2em;
	background: #f6f6f7;
	border-radius: 0.125em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	cursor: pointer;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 7.375em;
		height: 2em;
		line-height: 2em;
	}
	.el-input__icon {
		line-height: 2em;
	}
}
.rilibox {
	width: 100%;
}
</style>
